.uf_content_box {
  background-color: #fff;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  box-sizing: border-box;
  height: 99%;
  border-radius: 15px;

  .uf_content_wrapper {
    position: relative;
    background-color: #fff;
    height: 100%;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
    // padding: 10px 0;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content auto;

    // .uf_content_wrapper {
    //   padding: 0 !important;
    // }

    .uf_content_upper {
      margin-top: 15px;
      position: relative !important;
      display: flex !important;
      height: auto !important;
      min-height: 10px !important;
      width: 97% !important;
      justify-content: space-between !important;
      align-items: center !important;
      grid-column-start: 1 !important;
      justify-self: center !important;
      grid-row-start: 1 !important;

      .uf_content_title {
        font-size: 26px;
        font-weight: 600;
        color: rgba(34, 42, 96, 0.9);
        text-transform: capitalize;
      }
    }

    &.offer-preparation {
      padding-top: 0;

      .uf_content_wrapper {
        // grid-template-columns: none;
        grid-template-rows: 100% !important ;
      }

      grid-template-rows: 100% !important ;

      .uf_content_upper {
        display: none !important;
      }
    }

    .uf_content_additional_tabs {
      position: absolute;
      top: 50px;
      width: 97% !important;
      min-height: 10px !important;
      justify-content: space-between !important;
      align-items: center !important;
      grid-column-start: 1 !important;
      justify-self: center !important;
      grid-row-start: 1 !important;
    }

    .uf_content_main {
      position: relative;
      height: 95%;
      width: 100%;
      margin-top: 35px;
      gap: 5px;
      grid-template-rows: 50px auto;

      @media screen and (max-width: 1400px) {
        .uf_filters {
          margin-left: 25px !important;
        }
      }

      .uf_filters {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 25px;
        width: 100%;
        justify-self: center;
        margin-left: 25px;
      }
    }

    // .uf_content_wrapper_for_tools {
    //   min-width: 45px;
    //   width: auto;
    //   padding: 0;
    //   justify-content: center;
    //   align-items: center;
    //   position: relative;
    //   display: flex;
    //   min-height: 34px;
    //   border-radius: 10px;
    //   border: 1px solid rgba(34, 42, 96, 0.1);
    //   background: #fbfbfc;
    //   box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1) inset,
    //     2px 2px 4px 0px rgba(0, 0, 0, 0.2);
    // }
  }
}

.uf_content_wrapper {
  position: relative;
  background-color: #fff;
  height: 100%;
  width: 100%;
  // overflow: auto;
  box-sizing: border-box;
  // padding: 10px 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content auto;

  // .uf_content_wrapper {
  //   padding: 0 !important;
  // }

  .uf_content_upper {
    margin-top: 15px;
    position: relative !important;
    display: flex !important;
    height: auto !important;
    min-height: 10px !important;
    width: 97% !important;
    justify-content: space-between !important;
    align-items: center !important;
    grid-column-start: 1 !important;
    justify-self: center !important;
    grid-row-start: 1 !important;

    .uf_content_title {
      font-size: 26px;
      font-weight: 600;
      color: rgba(34, 42, 96, 0.9);
      text-transform: capitalize;
    }
  }

  &.offer-preparation {
    padding-top: 0;

    .uf_content_wrapper {
      // grid-template-columns: none;
      grid-template-rows: 100% !important ;
    }

    grid-template-rows: 100% !important ;

    .uf_content_upper {
      display: none !important;
    }
  }

  .uf_content_additional_tabs {
    position: absolute;
    top: 50px;
    width: 97% !important;
    min-height: 10px !important;
    justify-content: space-between !important;
    align-items: center !important;
    grid-column-start: 1 !important;
    justify-self: center !important;
    grid-row-start: 1 !important;
  }

  .uf_content_main {
    position: relative;
    height: 95%;
    width: 100%;
    margin-top: 35px;
    gap: 5px;
    grid-template-rows: 50px auto;

    @media screen and (max-width: 1400px) {
      .uf_filters {
        margin-left: 25px !important;
      }
    }

    .uf_filters {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 25px;
      width: 100%;
      justify-self: center;
      margin-left: 25px;
    }
  }

  // .uf_content_wrapper_for_tools {
  //   min-width: 45px;
  //   width: auto;
  //   padding: 0;
  //   justify-content: center;
  //   align-items: center;
  //   position: relative;
  //   display: flex;
  //   min-height: 34px;
  //   border-radius: 10px;
  //   border: 1px solid rgba(34, 42, 96, 0.1);
  //   background: #fbfbfc;
  //   box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.1) inset,
  //     2px 2px 4px 0px rgba(0, 0, 0, 0.2);
  // }
}

.table_isbns {
  border: 1px solid black;

  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    text-align: center;
  }
}

.view_request_comparation_modal.uf_filters {
  width: 300px !important;
  margin-left: 30px !important;

  .nx_textfield.nx_selectfield {
    margin-right: 20px;
  }

  .nx_checkbox > label > span {
    white-space: nowrap;
  }
}
