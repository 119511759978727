.filters_wrapper {
  border-radius: 10px !important;
  background: #f8fafb !important;
  box-shadow: 5px 5px 10px 0px rgba(34, 42, 96, 0.2),
    -5px -5px 10px 0px rgba(34, 42, 96, 0.07) !important;
}

.filters_card {
  color: rgba(34, 42, 96, 0.8) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  font-family: Poppins !important;
}

.tab_text {
  text-transform: capitalize !important;
  font-size: 16px !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.nx_search {
  position: relative;
  display: flex;
  width: 200px;
  height: 40px;
  padding: 6px 124px -1px 0 !important;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  box-shadow: unset !important;
  // box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.10) inset;
  border: 0 !important;
}
